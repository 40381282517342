header {
    background: #444;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 1);
    z-index: 2;
}

header .container {
    width: 100%;
    height: 100%;
    max-width: 900px;
    margin: auto;
}

.logo {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 10px;
}
.logo .st0 {
  fill:#00417E;
}
.logo .st1 {
  fill:#FFF;
}

.logo svg {
    width: auto;
    height: 2.5rem;
    margin-right: 20px;
}
.logo .title {
  font-size: 2rem;
  font-weight: bold;
  color: white;
}

@media screen and (max-width: 699px) {
  header {
      box-shadow: none;
  }
}